import {useEffect} from "react";
import {subscribeToMessage} from "../shared/events";

export function useSubscription(event, cb) {
  useEffect(() => {
    const unsubscribe = subscribeToMessage(event, cb);
    return () => {
      unsubscribe();
    };
  }, [cb, event]);
}
