import axios from 'axios';
import submitApiClient from "./submitApiClient";

export const linkMobilePhone = (phone, year, month, day) => axios.post('/internal/v1/mobilePhoneProfile', {
  phoneNumber: phone,
  dateOfBirth: [day, month, year].join('/')
});
export const verifyMobilePhone = (code) => axios.post('/internal/v1/mobilePhoneProfile/verify', {code});
export const unlinkMobilePhone = () => axios.delete('/internal/v1/mobilePhoneProfile');

export const updateProfile = (name, email, postcode) => axios.put('/internal/v1/userProfile', {
  name,
  email,
  postcode
});

/**
 * Sends a reset password link to an email provided
 * @returns {Promise<AxiosResponse<any>>}
 */
export const changeEmailProfilePassword = (email) => axios.post('/auth/recover', { email: email });

/**
 * deletes user account - (your right to be forgotten)
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteUserAccount = () => axios.delete('/internal/v1/user');


/**
 * generate agent login PIN
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createAgentLogin = () => axios.post('/internal/v1/agentLogin');

/**
 * delete agent login PIN
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteAgentLogin = (id) => axios.delete('/internal/v1/agentLogin/' + id);


/**
 * @param {Object} params
 * @param {String} params.sort
 * @param {String} params.order
 * @param {Boolean} params.unsubmittedOnly
 * @return {Promise<AxiosResponse<any>>}
 */
export const listForms = (params = {}) => axios.get(`/internal/v1/form?cacheBreaker=${new Date().getTime()}`, {
  headers: {
    'Cache-Control': 'no-store'
  },
  params: params
});
/**
 * @param {String|Number} id
 * @return {Promise<AxiosResponse<any>>}
 */
export const getForm = (id) => axios.get(`/internal/v1/form/${id}?cacheBreaker=${new Date().getTime()}`, {
  headers: {
    'Cache-Control': 'no-store'
  }
});
/**
 * @param {String} formTypeCode
 * @param {String} formName
 * @param {Number} pageCount
 * @return {Promise<AxiosResponse<any>>}
 */
export const createForm = (formTypeCode, formName, pageCount) => axios.post('/internal/v1/form', {formTypeCode, formName, pageCount});
/**
 * @param {String|Number} id
 * @param {Object} props
 * @param {String} props.formTypeCode
 * @param {String} props.formName
 * @param {Number} props.pageCount
 * @param {Number} props.lastValidPage
 * @param {Boolean} props.allPagesValidated
 * @param {Object} props.dirtyFields
 * @param {Object} props.submittedFields
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateForm = (id, props) => axios.put(`/internal/v1/form/${id}`, props);
/**
 * @param {String|Number} id
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteForm = (id) => axios.delete(`/internal/v1/form/${id}`);
/**
 * @param {String|Number} id
 * @param {Number} page - value of dirtyFields.page
 * @param {Boolean} allPagesValidated
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateFormMetadata = (id, page, allPagesValidated) => axios.put(`/internal/v1/form/${id}/updateMetadata`, { page, allPagesValidated });
/**
 * @param {String|Number} id
 * @param {Object|null} submittedFields
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateFormSubmittedState = (id, submittedFields) => axios.put(`/internal/v1/form/${id}/toggleSubmit`, { submittedFields });
/**
 * Meant for forms that are sent to third parties
 * @param {String|Number} id
 * @param {Object} params
 * @param {Object} params.formModel
 * @param {Object} params.dirtyModel
 * @param {String} params.pdfHtml
 * @return {Promise<AxiosResponse<any>>}
 */
export const remoteFormSubmit = (id, params) => submitApiClient.post(`/internal/v1/form/${id}/remoteSubmission`, params);
/**
 * Admins only
 * @param {String|Number} id
 * @return {Promise<AxiosResponse<any>>}
 */
export const remoteFormUnsubmit = (id) => axios.delete(`/internal/v1/form/${id}/remoteSubmission`);

