import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { flavour } from './config';
import { AuthContextProvider } from './auth/AuthContextProvider';
import { AppStateProvider } from './main/AppState';
import './styles/index.scss';
import App from './main/App';
import {AirbrakeErrorBoundary} from "./Airbrake";
import {APP_MODE} from "./env";

const onIntlError = error => {
  if (APP_MODE !== 'production') {
    console.warn(error);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <AirbrakeErrorBoundary>
      <IntlProvider locale="en" messages={flavour.flavourMessages} onError={onIntlError}>
        <AuthContextProvider>
          <AppStateProvider>
            <App />
          </AppStateProvider>
        </AuthContextProvider>
      </IntlProvider>
    </AirbrakeErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
