import React, {useLayoutEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {GOOGLE_ANALYTICS_ID} from "../env";

export function CookiePopup() {
  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    if (!isAgreed()) {
      setTimeout(() => {
        setIsActive(true);
      }, 1000);
    }
  }, []);

  //-------------------------------------------------------------------------------------------
  function enableGoogleAnalytics() {
    if (GOOGLE_ANALYTICS_ID) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = window.gtag || function() {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", GOOGLE_ANALYTICS_ID);
      window.gtag("consent", "update", { // grant consent for analytics_storage;
        analytics_storage: "granted",
      });

      //  Append the initial gtag script to the <head> section;
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "//www.googletagmanager.com/gtag/js?id=" + GOOGLE_ANALYTICS_ID;
      document.getElementsByTagName("head")[0].appendChild(script);
      console.info('Google analytics enabled for account ID', GOOGLE_ANALYTICS_ID);
    }
    else console.warn('Google analytics disabled for the current env.');
  }

  const isAgreed = () => document.cookie.indexOf("cookieConsentPresent=agreed") > -1;

  if (GOOGLE_ANALYTICS_ID) {
    const allowGoogleAnalytics = document.cookie.indexOf("allow_google_analytics=true") > -1;
    if (allowGoogleAnalytics) enableGoogleAnalytics();
    else {
      // initialize the data layer for Google tag manager
      // (this should be mandatory before the cookie banner is shown and allow_google_analytics is set)
      window.dataLayer = window.dataLayer || [];
      window.gtag = window.gtag || function() {
        window.dataLayer.push(arguments);
      };
      window.gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 500,
      });
    }
  }

  const agree = () => {
    const cookieExpiry = new Date((new Date().getTime()) + (3600 * 24 * 1000 * 365));
    document.cookie = 'cookieConsentPresent=agreed; Path=/; expires=' + cookieExpiry.toUTCString();
    document.cookie = 'allow_google_analytics=true; Path=/; expires=' + cookieExpiry.toUTCString();
    enableGoogleAnalytics();
    setIsActive(false);
  };

  const reject = () => {
    const cookieExpiry = new Date((new Date().getTime()) + (3600 * 24 * 1000 * 365));
    document.cookie = 'cookieConsentPresent=agreed; Path=/; expires=' + cookieExpiry.toUTCString();
    document.cookie = 'allow_google_analytics=false; Path=/; expires=' + cookieExpiry.toUTCString();
    setIsActive(false);
  };

  return (<>
    {isActive && <div role="dialog" aria-label="Cookie consent popup" className="cookie-popup bg-primary text-white">
      <small><FormattedMessage id="nav.cookies.explain" /></small>
      <div className="d-flex justify-content-start mt-2">
        <button type="button" className="btn btn-outline-light btn-sm" onClick={() => agree()}>
          <FormattedMessage id="nav.cookies.accept" />
        </button>
        <button type="button" className="btn btn-outline-light btn-sm ml-2" onClick={() => reject()}>
          <FormattedMessage id="nav.cookies.reject" />
        </button>
        <Link to="/cookie-policy" className="btn btn-outline-light btn-sm ml-2">
          <FormattedMessage id="nav.cookies.details" />
        </Link>
      </div>
    </div>}
  </>);
}
