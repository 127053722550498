import logoOverride from '../../images/logo';
import {APP_FLAVOUR} from "../../env";

const globalLinks = require('../links.json');
const globalMessages = require('../messages.json');

/**
 * All of the explicit conditionals matter as Webpack replaces them:
 *   `if (process.env.REACT_APP_FLAVOUR === "demo") { do something }`
 *   becomes
 *   `if(false){}`
 *   when condition does not match
 */
let customerLinkOverrides = {}, customerMessageOverrides = {}, customerLogo = logoOverride(APP_FLAVOUR);
if (APP_FLAVOUR === "blackpool"){
  customerLinkOverrides = require('./blackpool/links.json');
  customerMessageOverrides = require('./blackpool/messages.json');
}
if (APP_FLAVOUR === "cambridgeshire"){
  customerLinkOverrides = require('./cambridgeshire/links.json');
  customerMessageOverrides = require('./cambridgeshire/messages.json');
}
if (APP_FLAVOUR === "coventry"){
  customerLinkOverrides = require('./coventry/links.json');
  customerMessageOverrides = require('./coventry/messages.json');
}
if (APP_FLAVOUR === "demo"){
  customerLinkOverrides = require('./demo/links.json');
  customerMessageOverrides = require('./demo/messages.json');
}
if (APP_FLAVOUR === "dorset"){
  customerLinkOverrides = require('./dorset/links.json');
  customerMessageOverrides = require('./dorset/messages.json');
}
if (APP_FLAVOUR === "kent"){
  customerLinkOverrides = require('./kent/links.json');
  customerMessageOverrides = require('./kent/messages.json');
}
if (APP_FLAVOUR === "kirklees"){
  customerLinkOverrides = require('./kirklees/links.json');
  customerMessageOverrides = require('./kirklees/messages.json');
}
if (APP_FLAVOUR === "lincolnshire"){
  customerLinkOverrides = require('./lincolnshire/links.json');
  customerMessageOverrides = require('./lincolnshire/messages.json');
}
if (APP_FLAVOUR === "medway"){
  customerLinkOverrides = require('./medway/links.json');
  customerMessageOverrides = require('./medway/messages.json');
}
if (APP_FLAVOUR === "torbay"){
  customerLinkOverrides = require('./torbay/links.json');
  customerMessageOverrides = require('./torbay/messages.json');
}
if (APP_FLAVOUR === "wolverhampton"){
  customerLinkOverrides = require('./wolverhampton/links.json');
  customerMessageOverrides = require('./wolverhampton/messages.json');
}

export const flavourLinks = Object.keys(globalLinks).reduce((o, areaKey) => {
  const globalAreaLinks = globalLinks[areaKey] || [];
  const allowedGlobalLinks = globalAreaLinks.filter(category => {
    if (category.blacklist) return !category.blacklist.includes(APP_FLAVOUR);
    else return category;
  });
  const customerAreaLinks = customerLinkOverrides[areaKey] || [];
  o[areaKey] = [...allowedGlobalLinks, ...customerAreaLinks];
  return o;
}, {});

export const flavourMessages = { ...globalMessages, ...customerMessageOverrides };
export const brandLogo = customerLogo;



