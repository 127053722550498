import axios from 'axios';
import adapter from "axios/lib/adapters/xhr";
import { getToken } from '../auth/authClient.js';
import { publishMessage } from '../shared/events';
import { EventType } from '../shared/events/eventTypes';
import {APP_SERVER, SUBMIT_API} from "../env";

const submitApiClient = axios.create({
  baseURL: SUBMIT_API ?? APP_SERVER,
  adapter: async (config) => {
    if (config.url.startsWith('/internal/v1') && SUBMIT_API != null) {
      config.url = config.url.replace('/internal/v1', '');
    }
    return adapter(config);
  },
});
submitApiClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
  return config;
});
submitApiClient.interceptors.response.use(
  response => response,
  error => {
    publishMessage(EventType.AxiosError, error);
    return error;
  },
);
export default submitApiClient;
