import {topicCache, topicCacheKeys} from "./topicCache";
import {createTopic} from "./createTopic";

export function createIfNotExists(e) {
  let topic = topicCache.get(e);
  if (topic === undefined) {
    topicCacheKeys.add(e);
    topic = createTopic(e);
    topicCache.set(e, topic);
  }
  return topic;
}

export function publishMessage(e, message) {
  const topic = createIfNotExists(e);
  topic.publish(message);
}

export function subscribeToMessage(
  e,
  cb,
) {
  const topic = createIfNotExists(e);
  return topic.subscribe(cb);
}
