import React, { useState, useEffect } from 'react';
import { Page, PageNav } from '../shared/Page';
import { Secured } from '../auth/Secured';
import { useParams } from 'react-router';
import { getForm } from '../main/API';
import { DownloadPdfBtn } from "../forms/MainFormForPdf";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

export const AssessmentSubmitConfirmPage = () => {
  const intl = useIntl();
  const {formId} = useParams();
  const [form, setForm] = useState({});

  useEffect(() => {
    if (setForm && formId) {
      getForm(formId)
        .then(response => {
          setForm(response.data.data);
        })
        .catch(() => {
          alert('Could not load the form');
        });
    }
  }, [setForm, formId]);

  return (
    <Page>
      <PageNav title="nav.assessment.submitted" />
      <div className="row space-1">
        <div className="col">
          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "nav.assessment.submitted.message"})}} />
          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "nav.assessment.submitted.contact"})}} />
          <p>Please note: You can download a copy of your assessment from here or the Assessments page.</p>
          <hr />
          <DownloadPdfBtn className="btn btn-outline-secondary btn-lg mr-2" storedFormData={form} />
          <Link to="/assessment" className="btn btn-primary btn-lg mr-2">Continue</Link>
        </div>
      </div>
    </Page>
  );
};

export function AssessmentSubmitConfirmPageSecured () {
  return <Secured onSuccess={AssessmentSubmitConfirmPage}/>;
}
