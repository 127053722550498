// react-scripts will load .env and .env.local files into process.env
// If REACT_APP_NODE_ENV is set then .env.{REACT_APP_NODE_ENV} and .env.{REACT_APP_NODE_ENV}.local will be loaded
// If REACT_APP_NODE_ENV is not set then .env.development and .env.development.local will be loaded

export const APP_SERVER = process.env.REACT_APP_SERVER;
export const SUBMIT_API = process.env.REACT_APP_SUBMIT_API;
export const AUTH_GATEWAY = process.env.REACT_APP_AUTH_GATEWAY;
export const AIRBRAKE_PROJECT_ID = process.env.REACT_APP_AIRBRAKE_PROJ_ID;
export const AIRBRAKE_PROJECT_KEY = process.env.REACT_APP_AIRBRAKE_PROJ_KEY;
export const AIRBRAKE_ENVIRONMENT = process.env.REACT_APP_AIRBRAKE_ENV;
export const APP_FLAVOUR = process.env.REACT_APP_FLAVOUR;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS4_MID || null;

export const APP_MODE = process.env.NODE_ENV;
export const USE_GOOGLE_TRANSLATE = process.env.REACT_APP_GOOGLE_TRANSLATE === 'true';

export const TERMS_URL = process.env.REACT_APP_TERMS_URL;
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL;
