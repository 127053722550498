export function createTopic(type) {
  const subscribers = new Set();

  return {
    type,
    subscribe(cb) {
      subscribers.add(cb);
      return () => subscribers.delete(cb);
    },

    unsubscribeAll() {
      subscribers.clear();
    },

    publish(message) {
      subscribers.forEach(cb => cb(message));
    },
  };
}
