import ReactDOMServer from 'react-dom/server';
import React, {Fragment} from "react";
import { CommonDateTime } from '../shared/Dates';
import {flattenSections, getAvailableFormPath, canShowQuestion} from "./formRulesRunner";
import {allSchemas, personalDetails, personalDetailsKey} from "./formSchemas";
import {SchemaDocumentUtil} from "@lookinglocal/react-jsonschema-form-extensions";
import { suggestedExternalLinks } from '../main/ExternalLinks';
import moment from 'moment';
import {APP_SERVER} from "../env";

export function DownloadPdfBtn({storedFormData, className = 'btn btn-outline-secondary'}) {
  const html = generateFormHtml(storedFormData);
  return <form id="pdfDownloadForm" name='pdfDownloadForm' className="d-inline" method="POST" action={`${APP_SERVER}/internal/v1/htmlToPdf`}>
    <input type="hidden" name="html" value={html}/>
    <input type="hidden" name="formTypeCode" value={"CSA"} />
    <button type="submit" data-testid={`forms-item-${storedFormData.id}-download`} className={className} title="Download PDF">Download PDF</button>
  </form>;
}

/**
 * @param storedFormData {object} FormData representation in the database
 * @returns {string}
 */
export function generateFormHtml(storedFormData) {
  const previewHtml = ReactDOMServer.renderToString(<Preview storedFormData={storedFormData} />);

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
      <head>
        <title>Care assessment form</title>
        <style type="text/css">
          body { font-family: sans-serif; font-size: 12px; line-height: 1.5; color: black; background-color: #fff; }
          .table { margin-bottom: 15px; width: 100% }
          .table td {
            padding: 8px;
            border-bottom: 1px solid #999;
          }
        </style>
      </head>
      <body>
        <h1>Care assessment form</h1>
        ${previewHtml}
      </body>
    </html>`;
}

export function Preview({storedFormData}) {
  const formValues = storedFormData && storedFormData.dirtyFields ? JSON.parse(storedFormData.dirtyFields) : {};
  const suggestions = suggestedExternalLinks([storedFormData]) || [];
  return <div data-testid="preview">
    <FormDetailsPreview formData={storedFormData} />
    <PersonalDetailsPreview formValues={formValues} />
    <ActAreasPreview formValues={formValues} />
    <SuggestionsPreview suggestions={suggestions} />
  </div>;
}

export function generatePersonalDetailsHtmlTable(personalDetailsValues) {
  if (!personalDetailsValues) {
    return '';
  }
  let personalDetailsHtml = '<table class="table"><tbody>';
  let tmpHeading = null;
  SchemaDocumentUtil.processSchemaForDocument(personalDetails.schema, personalDetailsValues, (heading) => {
    tmpHeading = heading;
  }, ({title, values}) => {
    const cleanedValues = ((title && values) || []).filter(v => v != null && v !== '');
    if (cleanedValues.length) {
      if (tmpHeading) {
        // add heading only if there is a value underneath
        personalDetailsHtml += `<tr><td class="heading" colspan="2"><b>${tmpHeading}</b></td></tr>`;
        tmpHeading = null;
      }
      if (title === 'Date of birth') {
        personalDetailsHtml += `<tr><td class="label" width="50%">${title} (DD/MM/YYYY)</td><td class="value" width="50%">${moment(cleanedValues[0]).format('DD/MM/YYYY')}</td></tr>`;
      }
      else personalDetailsHtml += `<tr><td class="label" width="50%">${title}</td><td class="value" width="50%">${cleanedValues.join('<br>')}</td></tr>`;
    }
  }, (e) => {
    throw e;
  });
  personalDetailsHtml += '</tbody></table>';
  return personalDetailsHtml;
}

/**
 * Used both in the view and pdf
 */
export function PersonalDetailsPreview({formValues}) {
  const values = typeof formValues === 'object' && formValues ? formValues : {};
  if (!values[personalDetailsKey]) {
    return null;
  }
  const htmlString = generatePersonalDetailsHtmlTable(values[personalDetailsKey]);
  return (<div data-testid="personal-details-preview">
    <h2>Personal details</h2>
    <div dangerouslySetInnerHTML={{__html: htmlString}}/>
  </div>);
}

/**
 * Used both in the view and pdf
 */
export function FormDetailsPreview({formData}) {
  if (!formData) return null;
  return (<div data-testid="form-details-preview">
    <h2>Assessment details</h2>
    <table className="table">
      <tbody>
        <tr>
          <td width="50%" data-testid={`form-details-preview-id-label`}>ID</td>
          <td width="50%" data-testid={`form-details-preview-id-value`}>{formData.id}</td>
        </tr>
        <tr>
          <td width="50%" data-testid={`form-details-preview-dateCreated-label`}>Date started</td>
          <td width="50%" data-testid={`form-details-preview-dateCreated-value`}><CommonDateTime date={formData.dateCreated}/></td>
        </tr>
        <tr>
          <td width="50%" data-testid={`form-details-preview-lastUpdated-label`}>Last updated</td>
          <td width="50%" data-testid={`form-details-preview-lastUpdated-value`}><CommonDateTime date={formData.lastUpdated}/></td>
        </tr>
        <tr>
          <td width="50%" data-testid={`form-details-preview-formTypeCode-label`}>Form code</td>
          <td width="50%" data-testid={`form-details-preview-formTypeCode-value`}>{formData.formTypeCode}</td>
        </tr>
      </tbody>
    </table>
  </div>);
}

function SuggestionsPreview(suggestions) {
  const htmlString = generateSuggestionsListHTML(suggestions);
  return (<div data-testid="suggestions-preview">
    <h2><br/>Suggestions</h2>
    <div dangerouslySetInnerHTML={{__html: htmlString}}/>
  </div>);
}

function generateSuggestionsListHTML(slink) {
  const links = slink.suggestions || [];
  if (links.length > 0) {
    let suggestionsHtml = '<table class="table"><tbody>';
    links.forEach(link => suggestionsHtml += `<tr><td>${link.labels.join(', ')}</td><td>${link.url}</td></tr>`);
    suggestionsHtml += '</tbody></table>';
    return suggestionsHtml;
  }
  else return '';
}

/**
 * Used both in the view and pdf
 */
export function ActAreasPreview({formValues}) {
  const values = typeof formValues === 'object' && formValues ? formValues : {};
  const areaIdsInUse = Object.keys(values);
  const areaSchemasInUse = allSchemas.filter(schema => areaIdsInUse.indexOf(schema.id) > -1 );
  if (!areaSchemasInUse.length) {
    return null;
  }
  return (<div data-testid="act-areas-preview">
    <h2>Selected areas</h2>
    {areaSchemasInUse.map(schema => <ActAreaPreview key={schema.id} schema={schema} values={formValues[schema.id]} />)}
  </div>);
}

export function ActAreaPreview({schema, values}) {
  const path = getAvailableFormPath(schema, values);
  const sectionsPath = flattenSections(path);

  const renderPageItems = (sectionSchema, pageIndexes) => {
    return pageIndexes.map((pageIdx, pid) => {
      const pageSchema = sectionSchema.pages[pageIdx];
      const pageItems = pageSchema.items;

      let questionAnswers = pageItems.map((item, id) => {
        if (!canShowQuestion(item, values)) return null;

        const title = item.title;
        if (!title) return null;

        let value = (values && values[item.id] && values[item.id]) || null;
        if (Array.isArray(value)) {
          value = value.map((val, index) => <Fragment key={index}><span className="text-muted mb-3">{val}</span><br /></Fragment>);
        }
        else value = <span className="text-muted">{value || '-'}</span>;

        return <Fragment key={id}>
          {title && <p className="question" data-testid={`section-${sectionSchema.id}-page-${pageSchema.id}-item-title`} style={{marginLeft:20, marginBottom:3, listStyle:'square', display:'list-item'}}>
            {title}
          </p>}
          <p className="answer" data-testid={`section-${sectionSchema.id}-page-${pageSchema.id}-value`} style={{marginLeft:20}}>
            {value || '-'}
          </p>
        </Fragment>;
      });

      return (<Fragment key={pid}>
        {pageSchema.title && <p className="question" data-testid={`section-${sectionSchema.id}-page-${pageSchema.id}-title`} style={{marginLeft:20, marginBottom:3, listStyle:'square', display:'list-item'}}>
          {pageSchema.title}
        </p>}
        {questionAnswers}
      </Fragment>);
    });
  };

  return (<section>
    <h3 data-testid={`area-${schema.id}-title`}>{schema.title}</h3>
    {sectionsPath.map(section => {
      const sectionSchema = schema.sections[section[0]];
      const questionIndexes = section[1];
      return (<section key={sectionSchema.id} data-testid={`section-${sectionSchema.id}`}>
        <h4 className="area" data-testid={`section-${sectionSchema.id}-title`}>{sectionSchema.title}</h4>
        { renderPageItems(sectionSchema, questionIndexes) }
      </section>);
    })}
  </section>);
}
